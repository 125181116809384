import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import SectionWithColumnLayout from 'components/SectionWithColumnLayout'

const OptimalMarketingAb = ({ data }) => (
  <Layout
    pageTitle='Kundcase - Optimal Marketing AB'
    headerBackgroundImage={data.header.childImageSharp.gatsbyImageData}
    headerBackgroundPosition='100% 60%'
  >
    <oma-grid-row>
      <SectionWithColumnLayout title='Kundcase'>
        <h2 className='section__sub-heading'>Optimal Marketing AB</h2>
        <p className='section__text'>
          Madeleine Olsen driver sedan 2014 Optimal Marketing AB som erbjuder
          små och medelstora företag tjänster inom marknadsföring- och
          kommunikation. Det kan vara allt från några timmar/månad till att
          jobba under kundens ”egen flagg” som marknadschef.
        </p>
        <p className='section__text'>
          Madeleine drev tidigare en reklambyrå med drygt 10 medatbetare (2002 -
          2011) och det var också då hon kom i kontakt med Ertan.{' '}
          <i>
            Ertan skötte allt kring ekonomin och lönehantering åt oss på Holviks
            och gjorde så i många år. Det var därför helt naturligt att
            fortsätta jobba ihop med Ertan när jag drog igång Optimal Marketing
            AB,
          </i>{' '}
          säger Madeleine.
        </p>
        <p>
          <i>
            Det var också Ertan som introducerade Fortnox för oss redan på
            Holvikstiden. Jag sköter själv löpande fakturering, betalningar osv
            och gör det då genom Fornox, ett kanonbra verktyg som ger mig
            möjlighet att följa ekonomin på ett väldigt lätttillgängligt sätt.
            Ertan sköter sedan all bokföring, moms, skatter, bokslut mm och jag
            får ett mail med vad jag behöver göra i form av t ex
            skattebeatlningar osv - supersmidigt. Jag gillar att när man
            fokuserar 100% på det man är bäst på, jag kan marknadskommunikation
            och Ertan kan ekonomi och bokföring så detta upplägg är perfekt,
          </i>{' '}
          avslutar Madeleine.
        </p>
      </SectionWithColumnLayout>
    </oma-grid-row>
  </Layout>
)

export const query = graphql`
  {
    header: file(relativePath: { eq: "optimal-marketing.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          transformOptions: { cropFocus: NORTH }
          layout: FULL_WIDTH
        )
      }
    }
  }
`

export default OptimalMarketingAb
